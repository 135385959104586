import { CardWrapper } from '@components/CardComponents';
import Image from '@components/Image';
import { SubscriptionProductCollection } from 'fragile/types';
import { FC, memo, useState } from 'react';
import Link from 'next/link';
import { ROUTE_SUBSCRIPTION } from '@constants';
import ColorSelectButtons from 'fragile/components/ColorSelect/ColorSelectButtons';
import { useTranslation as t } from '@utils/hooks';
import cn from 'classnames';
import CardService from '@components/CardComponents/CardService';
import * as styles from './styles';

type Props = {
  subscriptionProductCollection: SubscriptionProductCollection;
  isSmall?: boolean;
  isExtended?: boolean;
};

const SubscriptionCard: FC<Props> = ({
  subscriptionProductCollection,
  isSmall = false,
  isExtended = false,
}) => {
  const { slug, name } = subscriptionProductCollection.fromCms;

  const upgradeOptions =
    subscriptionProductCollection?.products?.[0]?.upgrades?.[0]?.options?.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );

  const COLORS = t('message_colors');
  const COLOR = t('message_color');

  const colorText =
    upgradeOptions?.length > 1
      ? `${upgradeOptions?.length} ${COLORS}`
      : `${upgradeOptions?.length} ${COLOR}`;

  const initialOption =
    upgradeOptions &&
    upgradeOptions.find(option => option.name.includes('white'));

  const linkage = `/${ROUTE_SUBSCRIPTION}/${slug}`;

  const [selectedUpgradeOption, setUpgradeOption] = useState(initialOption);

  const image =
    selectedUpgradeOption &&
    selectedUpgradeOption?.fromCms?.images?.[0]?.responsiveImage;

  const friendlyPrice = `$${(
    subscriptionProductCollection.monthlyPrice / 100
  ).toFixed(2)}`;

  const { regionalServices } = subscriptionProductCollection.fromCms;
  const hasPromo = regionalServices?.bundlesPromos?.length > 0;

  return (
    <CardWrapper>
      <Link href={linkage} passHref role="link" tabIndex={0}>
        <div
          className="relative w-full p-4 xl:p-10 mb-1 bg-nzxt-light-grey-50 dark:bg-purpfiction"
          style={{
            background: subscriptionProductCollection?.fromCms?.gradient,
          }}
          aria-hidden
        >
          {image && (
            <Image
              className={styles.img}
              data={image}
              width={image.width}
              height={image.height}
            />
          )}
        </div>
      </Link>

      <div className="flex-grow mb-3 mt-1">
        <Link href={linkage} passHref role="link" tabIndex={0}>
          <div
            {...(isExtended ? { className: styles.container } : {})}
            style={{ flex: '0.5 0 0%', flexBasis: 'max-content' }}
          >
            <p
              className={styles.heading(isSmall)}
              data-test-id="card-heading"
              suppressHydrationWarning
            >
              {name}
            </p>
            <div className={styles.shortDescription}>
              <p className={styles.defaultColor} suppressHydrationWarning>
                {subscriptionProductCollection.fromCms.shortDescription}
              </p>
            </div>
          </div>
        </Link>
      </div>
      {!isExtended && hasPromo && (
        <div className={styles.servicesWrapper}>
          {regionalServices?.shippingNotices ? (
            <CardService
              slug={slug}
              service={regionalServices?.shippingNotices}
            />
          ) : null}
          {regionalServices?.bundlesPromos ? (
            <CardService
              slug={slug}
              service={regionalServices?.bundlesPromos}
            />
          ) : null}
        </div>
      )}
      {isSmall && (
        <p className="text-xs mb-1 md:hidden visible text-nzxt-light-grey-400">
          {colorText}
        </p>
      )}
      <div className={styles.optionsContainer}>
        <div
          className={cn({ 'text-xs md:text-sm': isSmall })}
          suppressHydrationWarning
        >
          {friendlyPrice}
          <span className="text-nzxt-light-grey-600">/month</span>
        </div>
        {selectedUpgradeOption && (
          <ColorSelectButtons
            selectedUpgradeOption={selectedUpgradeOption}
            setUpgradeOption={setUpgradeOption}
            upgradeOptions={upgradeOptions}
            isSmall
          />
        )}
      </div>
    </CardWrapper>
  );
};

export default memo(SubscriptionCard);
